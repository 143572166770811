import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/common/Layout/layout"
import SEO from "../../components/common/SEO/seo"
import BannerPage from "../../components/banner/BannerPage/bannerPage"
import SectionTextImage from "../../components/section/TextImage/textImage"
import CallToAction from "../../components/section/CallToAction/callToAction"

const MerchandisingPage = () => {
    const data = useStaticQuery(graphql`
    query {
      merchandisingImage1: file(relativePath: { eq: "images/sections/produccion-grafica/merchandising/por-que-hacer-merchandising-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      merchandisingImage2: file(relativePath: { eq: "images/sections/produccion-grafica/merchandising/la-personalizacion-de-tu-publicidad-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      merchandisingImage3: file(relativePath: { eq: "images/sections/produccion-grafica/merchandising/la-magia-de-los-gadgets-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO
                title="Merchandising"
                description="Imprenta en Barcelona experta en merchandising. Imprimimos todo tipo de artículos y soportes publicitarios para empresas."
            />

            <BannerPage
                title="Merchandising, la personalización  de productos"
                text="Impresión de productos promocionales para eventos y ferias"
                color="blue"
            />

            <SectionTextImage
                title1="¿Por qué hacer "
                title2="merchandising?"
                text={<Fragment>
                    Por qué los <strong>artículos publicitarios</strong> cumplen 3 funciones publicitarias básicas que aportan grandes beneficios para una empresa: <br /><br />
                    <ol className="text-left">
                        <li>Son un <strong>excelente reclamo</strong> y sirven de recordatorio.</li>
                        <li>Son el <strong>escaparate perfecto</strong> para mostrar los servicios o productos que ofrece una marca.</li>
                        <li>Son productos cotidianos con un <strong>bajo coste</strong>, idóneos para dar visibilidad.</li>
                    </ol>
                </Fragment>}
                image={data.merchandisingImage1.childImageSharp.fluid}
                imageAlt="¿Por que hacer merchandising? - Agencia Merchandising"
                color="blue"
                containerTextRight={false}
            />

            <SectionTextImage
                title1="La personalización "
                title2="de tu publicidad"
                text={<Fragment>
                    En IBX somos <strong>expertos en merchandising</strong> y personalización de todo tipo de productos para eventos, ferias, promoción de empresas, etc. Desde libretas, bolígrafos, tazas, identificadores, mochilas, bolsas, calendarios, llaveros, lanyards, etc. <br /><br />
                    Producimos lo que necesites, con la imagen y el diseño que prefieras y con el logo de tu marca. Podemos personalizar todo tipo de productos y en una <strong>amplia variedad de formatos y colores</strong>.
                </Fragment>}
                image={data.merchandisingImage2.childImageSharp.fluid}
                imageAlt="Merchandising personalizado - Agencia Merchandising"
                color="blue"
                containerTextRight={true}
            />

            <SectionTextImage
                title1="La magia "
                title2="de los gadgets"
                text={<Fragment>
                    El <strong>merchandising</strong> o la entrega de <strong>regalos promocionales</strong> es una estrategia clara para incentivar las ventas. Permite fidelizar a clientes y captar a nuevos, ya que estos obsequios se pueden convertir en una excelente manera de realizar una primera toma de contacto con tu público, por ejemplo en ferias o eventos. <br /><br />
                    Una forma original e ideal de fidelizar a tu público y favorecer la cercanía y atención al cliente.
                </Fragment>}
                image={data.merchandisingImage3.childImageSharp.fluid}
                imageAlt="Gadgets - Agencia Merchandising"
                color="blue"
                containerTextRight={false}
            />

            <CallToAction
                color="blue"
                title="Apuesta por el merchandising como parte de tu estrategia publicitaria"
                text={<Fragment>
                    ¡Somos expertos en <strong>impresión de artículos promocionales en Barcelona</strong>! <br />
                    ¿Qué gadget quieres?
                </Fragment>}
                button="¡Llámanos ahora!"
                buttonUrl="/contacto"
            />

        </Layout>
    )

}
export default MerchandisingPage
